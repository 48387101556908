import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.join";
import "core-js/modules/es.array.map";
import "core-js/modules/es.function.name";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.regexp.to-string";
import "core-js/modules/es.string.split";
import _objectSpread from "/data/jenkins/workspace/prod-e-retail/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { spreadsy as retrieveReq, deleteSpreads as deleteBatchReq, updataSpreads as updateReq, generalBank as createReq, updateLink, getTemplateList as _getTemplateList, batchUpdateQrCodeBg } from "../api";
import QRCode from "qrcodejs2";
import html2canvas from "html2canvas";
import { RecordForm } from "../FormMechanism.js";
import { CurdMixin } from "xqjr-plugin-common"; // ----------------------------------------------------- 构建表格配置，包括基本项、操作

var columns = RecordForm.list.filter(function (item) {
  return item.customize.table;
}).map(function (item) {
  return {
    dataIndex: item.model,
    customTitle: item.label,
    fixed: item.model === "name" ? "left" : null,
    slots: {
      title: "custom_".concat(item.model)
    },
    help: item.customize.help && item.customize.help.length > 0 ? item.customize.help : null,
    scopedSlots: {
      customRender: item.model
    },
    type: item.type,
    options: item.options,
    sorter: item.customize.sort ? function () {} : null,
    sortDirections: item.customize.sort ? ["descend", "ascend"] : null,
    width: item.model !== "extendLink" ? 150 : "auto"
  };
});
columns.push({
  width: 180,
  title: "操作",
  dataIndex: "crudAction",
  scopedSlots: {
    customRender: "crudAction"
  },
  type: "action",
  fixed: "right"
}); // ----------------------------------------------------- 生成 mixin

var mixin = new CurdMixin({
  retrieveReq: retrieveReq,
  deleteBatchReq: deleteBatchReq,
  createReq: createReq,
  updateReq: updateReq,
  RecordForm: RecordForm,
  columns: columns
}); // ----------------------------------------------------- 主要内容，一般情况下基本都是空的，所有内容都在 mixin 中

export default {
  components: {},
  mixins: [mixin],
  data: function data() {
    return {
      uploadUrl: process.env.VUE_APP_SPREAD,
      updateLinkVisible: false,
      checkVisible: false,
      templetelist: [],
      name: "",
      recordBackground: "",
      channelInfoId: "",
      pageSize: 20,
      batchEditForm: {
        background: ""
      },
      rules: {
        background: [{
          required: true,
          message: "请选择模板",
          trigger: "change"
        }]
      },
      batchEditVisible: false
    };
  },
  computed: {
    // JSON表单需要请求参数
    dynamicParams: function dynamicParams() {
      return {
        enumOptions: {},
        enumOptionsl: {},
        enumOptions2: {
          kind: 1
        }
      };
    },
    retrieveParams: function retrieveParams() {
      return {
        request: {
          source: process.env.VUE_APP_SOURCE,
          type: 1,
          channelId: localStorage.getItem("lesseeId")
        }
      };
    },
    // 创建所需参数
    createParams: function createParams() {
      return {
        enabled: 1,
        source: process.env.VUE_APP_SOURCE,
        type: 1
      };
    }
  },
  created: function created() {
    this.getTemplateList();
  },
  mounted: function mounted() {},
  methods: {
    onCopy: function onCopy(e) {
      this.$message.success("复制成功");
    },
    onError: function onError(e) {
      this.$message.success("复制失败");
    },
    serchEnter: function serchEnter() {
      this.retrieveRecord();
    },
    retrieveRecord: function retrieveRecord(values) {
      var _this = this;

      // 加载记录
      var params = this.retrieveParams.request;
      params.name = this.name;
      this.toggleSpinning();
      retrieveReq(params).then(function (res) {
        if (res.success === true) {
          var tempTotal = _this.chainRes(res, _this.entityCountTail);

          _this.tableData = _this.chainRes(res, _this.entitiesTail).map(function (item) {
            return Object.assign(item, {
              productIds: item.productIds.split(",")
            });
          });
          _this.total = tempTotal || tempTotal === 0 ? tempTotal : _this.tableData.length;
        }

        _this.toggleSpinning();
      }).catch(function (err) {
        console.log("debug log --> ", err);

        _this.toggleSpinning();
      });
    },
    createRecord: function createRecord(values) {
      var _this2 = this;

      // 创建单条记录
      this.toggleSpinning();
      createReq(_objectSpread(_objectSpread(_objectSpread({}, values), this.createParams), {}, {
        productIds: values.productIds.join(",")
      })).then(function (res) {
        if (res.success === true) {
          _this2.$message.success("添加成功");

          _this2.retrieveRecord();

          _this2.editCancel();
        } else {
          _this2.$message.error("添加失败");
        }

        _this2.toggleSpinning();
      }).catch(function (err) {
        console.log("debug log --> ", err);

        _this2.toggleSpinning();
      });
    },
    updateRecord: function updateRecord(values) {
      var _this3 = this;

      // 更新单条记录
      this.toggleSpinning();
      updateReq(Object.assign({}, this.tempRecord, values, {
        productIds: values.productIds.join(",")
      })).then(function (res) {
        if (res.success === true) {
          _this3.$message.success("修改成功");

          _this3.retrieveRecord();

          _this3.tempRecord = null;

          _this3.editCancel();
        } else {
          _this3.$message.error("修改失败");
        }

        _this3.toggleSpinning();
      }).catch(function (err) {
        _this3.$message.error(err.message);

        console.log("debug log --> ", err);

        _this3.toggleSpinning();
      });
    },
    enumOptionsFormatter: function enumOptionsFormatter(item) {
      return {
        value: item.id.toString(),
        label: item.name
      };
    },
    // 获取渠道信息
    getTemplateList: function getTemplateList() {
      var _this4 = this;

      _getTemplateList({
        type: 1
      }).then(function (res) {
        _this4.templetelist = res.data;
      });
    },
    // 是否使用
    changeUsed: function changeUsed(e, record) {
      var _this5 = this;

      updateReq({
        id: record.id,
        enabled: e
      }).then(function (res) {
        _this5.$message.success("操作成功");

        _this5.retrieveRecord();
      });
    },
    // 更新打开
    updateLinkReq: function updateLinkReq(record) {
      this.updateLinkVisible = true;
      this.channelInfoId = record.id;
    },
    // 更新推广渠道
    confirmUpdateLink: function confirmUpdateLink() {
      var _this6 = this;

      updateLink(this.channelInfoId).then(function (res) {
        _this6.updateLinkVisible = false;

        _this6.$message.success("更新成功");

        _this6.retrieveRecord();
      });
    },
    // 生成二维码
    qrcode: function qrcode(extendLink, idName) {
      new QRCode(idName, {
        width: 124,
        height: 124,
        // 高度
        text: extendLink // 二维码内容

      });
    },
    // 生成图片和二维码
    checkReq: function checkReq(record) {
      // this.templetelist.forEach(temp => {
      //   if (record.backgroundSpread === temp.id) {
      //     this.recordBackground = temp.background;
      //     console.log(this.recordBackground, 999);
      //   }
      // });
      this.recordBackground = record.background;
      this.channelInfoId = record.id;
      this.checkVisible = true;
      this.$nextTick(function () {
        this.qrcode(process.env.VUE_APP_SPREAD + record.extendLink, "qrcode");
      });
    },
    // 下载
    savecanvas: function savecanvas() {
      html2canvas(document.getElementById("qrbox"), {
        useCORS: true,
        allowTaint: false
      }).then(function (canvas) {
        var MIME_TYPE = "image/png";
        var imgURL = canvas.toDataURL("image/png");
        console.log(imgURL, 1111);
        var link = document.createElement("a");
        link.download = "";
        link.href = imgURL;
        link.dataset.downloadurl = [MIME_TYPE, link.download, link.href].join(":");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    // 批量编辑模板
    batchEditTemp: function batchEditTemp() {
      this.batchEditVisible = true;
      this.previewSrc = "";
    },
    // 批量编辑模板确定
    confirmBatchEdit: function confirmBatchEdit() {
      var _this7 = this;

      this.$refs.ruleForm.validate(function (valid) {
        if (valid) {
          var batchAddDTO = {
            channelIds: _this7.selectedRowKeys,
            background: _this7.batchEditForm.background
          };
          batchUpdateQrCodeBg(batchAddDTO).then(function (res) {
            _this7.batchEditVisible = false;

            _this7.$message.success("编辑成功");

            _this7.retrieveRecord();
          });
        } else {
          return false;
        }
      });
    },
    // 取消
    cancel: function cancel() {
      this.updateLinkVisible = false;
      this.checkVisible = false;
      this.batchEditVisible = false;
    }
  }
};