var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pSlide" },
    [
      _c(
        "a-layout",
        [
          _c(
            "a-layout-sider",
            { attrs: { width: 225 } },
            [
              _c(
                "div",
                {
                  staticClass: "pSlide-title pd_l16",
                  on: {
                    mouseenter: function($event) {
                      _vm.addIcon = true
                    },
                    mouseleave: function($event) {
                      _vm.addIcon = false
                    },
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.getAllList($event)
                    }
                  }
                },
                [
                  _vm._v(" 产品规则组 "),
                  _c("a-icon", {
                    staticClass: "mg_l81",
                    staticStyle: { "font-weight": "500" },
                    attrs: { type: "plus" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.newGroup($event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "a-menu",
                { staticStyle: { height: "calc(100vh - 250px)" } },
                _vm._l(_vm.groupList, function(item) {
                  return _c(
                    "a-menu-item",
                    {
                      key: item.id,
                      staticClass: "menu",
                      on: {
                        mouseenter: function($event) {
                          _vm.act = item.id
                        },
                        mouseleave: function($event) {
                          _vm.act = false
                        },
                        click: function($event) {
                          return _vm.getApprovalList(item)
                        }
                      }
                    },
                    [
                      _c(
                        "a-row",
                        {
                          attrs: {
                            type: "flex",
                            align: "middle",
                            justify: "space-between"
                          }
                        },
                        [
                          _c(
                            "a-col",
                            { staticClass: "ove", attrs: { span: 20 } },
                            [
                              _c(
                                "a-tooltip",
                                { attrs: { placement: "rightTop" } },
                                [
                                  _c("template", { slot: "title" }, [
                                    _c("span", [_vm._v(_vm._s("" + item.name))])
                                  ]),
                                  _c("span", [_vm._v(_vm._s("" + item.name))])
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            [
                              _c("a-icon", {
                                attrs: { type: "setting" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.setting(item)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-layout",
            [
              _c(
                "a-row",
                { staticClass: "lay-head" },
                [
                  _c(
                    "a-col",
                    { attrs: { span: "22" } },
                    [
                      _c("a-input-search", {
                        staticStyle: { width: "200px" },
                        attrs: { allowClear: "", placeholder: "搜索" },
                        on: { search: _vm.onSearch },
                        model: {
                          value: _vm.fieldName,
                          callback: function($$v) {
                            _vm.fieldName = $$v
                          },
                          expression: "fieldName"
                        }
                      })
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    [
                      _c(
                        "a-button",
                        {
                          staticStyle: { float: "right" },
                          attrs: { type: "primary", icon: "plus" },
                          on: {
                            click: function($event) {
                              return _vm.createApproval()
                            }
                          }
                        },
                        [_vm._v(" 新建规则 ")]
                      )
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-layout-content",
                [
                  _c(
                    "a-table",
                    {
                      staticStyle: {
                        "margin-top": "20px",
                        "margin-left": "20px"
                      },
                      attrs: {
                        "data-source": _vm.data,
                        rowKey: "id",
                        pagination: _vm.paginationOpt
                      }
                    },
                    [
                      _c("a-table-column", {
                        attrs: { title: "字段名称" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(text) {
                              return [_vm._v(_vm._s(text.fieldName))]
                            }
                          }
                        ])
                      }),
                      _c("a-table-column", {
                        attrs: { title: "字段规则" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(text) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(text.type == 1 ? "范围" : "枚举") +
                                    " "
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("a-table-column", {
                        attrs: { title: "最大值" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(text) {
                              return [_vm._v(" " + _vm._s(text.max) + " ")]
                            }
                          }
                        ])
                      }),
                      _c("a-table-column", {
                        attrs: { title: "最小值" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(text) {
                              return [_vm._v(" " + _vm._s(text.min) + " ")]
                            }
                          }
                        ])
                      }),
                      _c("a-table-column", {
                        attrs: { title: "学历" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(text) {
                              return [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm._f("filterType")(text.profession)
                                    ) +
                                    " "
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("a-table-column", {
                        attrs: { title: "操作", width: 300 },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(text) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function($event) {
                                        return _vm.updateRules(text)
                                      }
                                    }
                                  },
                                  [_vm._v(" 编辑 ")]
                                ),
                                _c("a-divider", {
                                  attrs: { type: "vertical" }
                                }),
                                _c(
                                  "span",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { type: "small" },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteFieldRules(text)
                                      }
                                    }
                                  },
                                  [_vm._v(" 删除 ")]
                                )
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("new-group", { attrs: { newGroupOptions: _vm.newGroupOptions } }),
      _c(
        "a-modal",
        {
          attrs: { title: _vm.title, visible: _vm.visible },
          on: {
            cancel: function($event) {
              _vm.visible = false
            }
          }
        },
        [
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                "a-button",
                {
                  key: "back",
                  on: {
                    click: function($event) {
                      _vm.visible = false
                    }
                  }
                },
                [_vm._v(" 返回 ")]
              ),
              _vm.title == "新增"
                ? _c(
                    "a-button",
                    {
                      key: "submit",
                      attrs: { type: "primary" },
                      on: { click: _vm.handleOk }
                    },
                    [_vm._v(" 确定 ")]
                  )
                : _c(
                    "a-button",
                    {
                      key: "submit",
                      attrs: { type: "primary" },
                      on: { click: _vm.handleUpdate }
                    },
                    [_vm._v(" 确定 ")]
                  )
            ],
            1
          ),
          _c(
            "a-form",
            {
              attrs: {
                form: _vm.form,
                labelCol: { span: 5 },
                wrapperCol: { span: 19 }
              }
            },
            [
              _c(
                "a-form-item",
                { attrs: { label: "规则分组" } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "ruleGroupId",
                            {
                              rules: [{ required: true, message: "请选择" }]
                            }
                          ],
                          expression:
                            "[\n            'ruleGroupId',\n            {\n              rules: [{ required: true, message: '请选择' }]\n            }\n          ]"
                        }
                      ],
                      attrs: { allowClear: "", placeholder: "请选择" }
                    },
                    _vm._l(_vm.groupList, function(item, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: item.id } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "字段设置" } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "fieldSetId",
                            {
                              rules: [{ required: true, message: "请选择" }]
                            }
                          ],
                          expression:
                            "[\n            'fieldSetId',\n            {\n              rules: [{ required: true, message: '请选择' }]\n            }\n          ]"
                        }
                      ],
                      attrs: { allowClear: "", placeholder: "请选择" }
                    },
                    _vm._l(_vm.fieldList, function(item, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: item.id } },
                        [_vm._v(_vm._s(item.fieldVO.name))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _c(
                "a-form-item",
                { attrs: { label: "类型" } },
                [
                  _c(
                    "a-select",
                    {
                      directives: [
                        {
                          name: "decorator",
                          rawName: "v-decorator",
                          value: [
                            "type",
                            {
                              rules: [{ required: true, message: "请选择" }]
                            }
                          ],
                          expression:
                            "[\n            'type',\n            {\n              rules: [{ required: true, message: '请选择' }]\n            }\n          ]"
                        }
                      ],
                      attrs: { allowClear: "", placeholder: "请选择" },
                      on: { change: _vm.handleSelectChange }
                    },
                    _vm._l(_vm.list, function(item, index) {
                      return _c(
                        "a-select-option",
                        { key: index, attrs: { value: item.id } },
                        [_vm._v(_vm._s(item.name))]
                      )
                    }),
                    1
                  )
                ],
                1
              ),
              _vm.type == 1
                ? _c(
                    "a-form-item",
                    { attrs: { label: "最大值" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "max",
                              { rules: [{ required: true, message: "请输入" }] }
                            ],
                            expression:
                              "['max', { rules: [{ required: true, message: '请输入' }] }]"
                          }
                        ]
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type == 1
                ? _c(
                    "a-form-item",
                    { attrs: { label: "最小值" } },
                    [
                      _c("a-input", {
                        directives: [
                          {
                            name: "decorator",
                            rawName: "v-decorator",
                            value: [
                              "min",
                              { rules: [{ required: true, message: "请输入" }] }
                            ],
                            expression:
                              "['min', { rules: [{ required: true, message: '请输入' }] }]"
                          }
                        ]
                      })
                    ],
                    1
                  )
                : _vm._e(),
              _vm.type === 2
                ? _c(
                    "a-form-item",
                    { attrs: { label: "学历" } },
                    [
                      _c(
                        "a-select",
                        {
                          directives: [
                            {
                              name: "decorator",
                              rawName: "v-decorator",
                              value: [
                                "profession",
                                {
                                  rules: [{ required: true, message: "请选择" }]
                                }
                              ],
                              expression:
                                "[\n            'profession',\n            {\n              rules: [{ required: true, message: '请选择' }]\n            }\n          ]"
                            }
                          ],
                          attrs: { allowClear: "", placeholder: "请选择" }
                        },
                        _vm._l(_vm.evelList, function(item, index) {
                          return _c(
                            "a-select-option",
                            { key: index, attrs: { value: item.id } },
                            [_vm._v(_vm._s(item.name))]
                          )
                        }),
                        1
                      )
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        2
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }