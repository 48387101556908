import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.map";
import _objectSpread from "/data/jenkins/workspace/prod-e-retail/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// ----------------------------------------------------- 依赖导入，包括 api、Form.json、Mixin
import { retrieveReq } from "./api";
import RecordForm from "./Form.json";
import { CurdMixin } from "xqjr-plugin-common";
import { mapGetters } from "vuex"; // ----------------------------------------------------- 构建表格配置，包括基本项、操作

var columns = RecordForm.list.filter(function (item) {
  return item.customize.table;
}).map(function (item) {
  return {
    dataIndex: item.model,
    customTitle: item.label,
    fixed: item.model === "name" ? "left" : null,
    slots: {
      title: "custom_".concat(item.model)
    },
    help: item.customize.help && item.customize.help.length > 0 ? item.customize.help : null,
    scopedSlots: {
      customRender: item.model
    },
    type: item.type,
    options: item.options,
    sorter: item.customize.sort ? function () {} : null,
    sortDirections: item.customize.sort ? ["descend", "ascend"] : null,
    width: item.customize.width
  };
}); // ----------------------------------------------------- 生成 mixin

var mixin = new CurdMixin({
  retrieveReq: retrieveReq,
  RecordForm: RecordForm,
  columns: columns
}); // ----------------------------------------------------- 主要内容，一般情况下基本都是空的，所有内容都在 mixin 中

export default {
  components: {},
  filters: {
    filterType: function filterType(val) {
      var typeArr = ["人员", "订单", "其他"];
      return typeArr[val - 1];
    }
  },
  mixins: [mixin],
  data: function data() {
    return {
      pageSize: 10
    };
  },
  computed: _objectSpread(_objectSpread({}, mapGetters(["permissions"])), {}, {
    isAdmin: function isAdmin() {
      return this.permissions ? this.permissions.isAdmin : false;
    },
    retrieveParams: function retrieveParams() {
      return {
        request: {
          channelId: localStorage.getItem("lesseeId")
        }
      };
    }
  }),
  created: function created() {},
  mounted: function mounted() {},
  methods: {}
};