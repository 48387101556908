import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.splice";
import "core-js/modules/web.dom-collections.for-each";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { getProductType } from "../api";
import LoanProd from "./loanProd";
import DepositProd from "./depositProd";
export default {
  name: "Product",
  components: {
    LoanProd: LoanProd,
    DepositProd: DepositProd
  },
  data: function data() {
    return {
      productType: "0",
      productTypelist: []
    };
  },
  computed: {
    productTypelists: function productTypelists() {
      var arry = JSON.parse(JSON.stringify(this.productTypelist));
      return arry.length === 3 ? arry.splice(0, 2) : arry;
    }
  },
  created: function created() {
    this.getEnableProTypeList();
  },
  methods: {
    callback: function callback(key) {
      this.productType = key;
    },
    getEnableProTypeList: function getEnableProTypeList() {
      var _this = this;

      this.productTypelist = [];
      getProductType().then(function (res) {
        console.log(res, 9999);
        res.data.forEach(function (ele) {
          if (ele.enable === true) {
            _this.productTypelist.unshift(ele);
          }
        });
      });
    }
  }
};