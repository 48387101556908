import _objectSpread from "/data/jenkins/workspace/prod-e-retail/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import request from "@/utils/request"; // 查询产品信息

export function getAllProduct(data) {
  return request({
    url: "/finance/api/v1/products/listAll",
    method: "GET",
    params: _objectSpread({}, data)
  });
}
export function getProductType(data) {
  return request({
    url: "/finance/api/v1/productTypemenager/listAll",
    method: "GET",
    params: data
  });
} // 删除单个产品模板和二维码模板，彻底删除

export function delTemplate(data) {
  return request({
    url: "/finance/api/v1/templates/".concat(data),
    method: "DELETE"
  });
}
export function updateHotProductBatch(data) {
  return request({
    url: "/finance/api/v1/products/updateHotProductBatch",
    method: "PUT",
    data: data
  });
} // 查询产品模板和二维码模板

export function getTemplateList(data) {
  return request({
    url: "/finance/api/v1/templates/listAll",
    method: "GET",
    params: _objectSpread(_objectSpread({}, data), {}, {
      channelId: localStorage.getItem("lesseeId")
    })
  });
} // 新增产品模板和二维码模板

export function addTemplate(data) {
  return request({
    url: "/finance/api/v1/templates",
    method: "POST",
    data: _objectSpread(_objectSpread({}, data), {}, {
      channelId: localStorage.getItem("lesseeId")
    })
  });
} // 滞纳金
// 查询公式模板

export function getFormulaslist(params) {
  return request({
    url: "/finance/api/v1/formulas",
    method: "GET",
    params: params
  });
} // 新增公式模板

export function addFormula(data) {
  return request({
    url: "/finance/api/v1/formulas",
    method: "POST",
    data: data
  });
} // 修改公式模板

export function updateFormula(data) {
  return request({
    url: "/finance/api/v1/formulas",
    method: "PUT",
    data: data
  });
} // 删除单个公式模板，彻底删除

export function delFormula(formulaId) {
  return request({
    url: "/finance/api/v1/formulas/".concat(formulaId),
    method: "DELETE"
  });
} // 产品启用
// 查询产品启用类型

export function getEnableProductType(data) {
  return request({
    url: "/finance/api/v1/siteConfigs/getEnableProductType",
    method: "GET",
    params: data
  });
} // 修改产品启用

export function updateEnableProductType(data) {
  return request({
    url: "/finance/api/v1/siteConfigs/updateEnableProductType",
    method: "PUT",
    data: data
  });
} // 修改产品启用

export function productTypeManager(data) {
  return request({
    url: "/finance/api/v1/productTypemenager",
    method: "PUT",
    data: data
  });
} // 分页查询字段配置

export function fieldSets(params) {
  return request({
    url: "/finance/api/v1/fieldSets",
    method: "GET",
    params: params
  });
} // 分页查询字段配置

export function fieldUpdate(data) {
  return request({
    url: "/finance/api/v1/fieldSets",
    method: "PUT",
    data: data
  });
} // 新增规则分组

export function ruleGroups(data) {
  return request({
    url: "/finance/api/v1/ruleGroups",
    method: "POST",
    data: data
  });
} // 修改规则分组

export function ruleGroupsUpdate(data) {
  return request({
    url: "/finance/api/v1/ruleGroups",
    method: "PUT",
    data: data
  });
} // 删除规则分组

export function ruleGroupsDelete(ruleGroupId) {
  return request({
    url: "/finance/api/v1/ruleGroups/".concat(ruleGroupId),
    method: "DELETE"
  });
} // 查询规则分组

export function ruleGroupsListAll() {
  return request({
    url: "/finance/api/v1/ruleGroups/listAll",
    method: "GET"
  });
} // 查询字段设置

export function fieldSetsListAll() {
  return request({
    url: "/finance/api/v1/fieldSets/listAll",
    method: "GET"
  });
} // 新增字段设置

export function fieldSetsCreate(data) {
  return request({
    url: "/finance/api/v1/fieldRules",
    method: "POST",
    data: data
  });
} // 修改字段设置

export function fieldSetsUpdate(data) {
  return request({
    url: "/finance/api/v1/fieldRules",
    method: "PUT",
    data: data
  });
} // 分页查询字段设置

export function fieldRules(params) {
  return request({
    url: "/finance/api/v1/fieldRules",
    method: "GET",
    params: params
  });
} // 删除规则分组

export function fieldRulesDelete(fieldRuleId) {
  return request({
    url: "/finance/api/v1/fieldRules/".concat(fieldRuleId),
    method: "DELETE"
  });
}