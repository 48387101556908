import { render, staticRenderFns } from "./baseinfoEditable.vue?vue&type=template&id=5520598a&"
import script from "./baseinfoEditable.vue?vue&type=script&lang=js&"
export * from "./baseinfoEditable.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/data/jenkins/workspace/prod-e-retail/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('5520598a')) {
      api.createRecord('5520598a', component.options)
    } else {
      api.reload('5520598a', component.options)
    }
    module.hot.accept("./baseinfoEditable.vue?vue&type=template&id=5520598a&", function () {
      api.rerender('5520598a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/views/Customer/detail/baseinfoEditable.vue"
export default component.exports