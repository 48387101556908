import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.join";
import "core-js/modules/es.object.to-string";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.split";
import "core-js/modules/web.dom-collections.for-each";
import request from "@/utils/request"; // 分页查询项目

export function retrieveReq(params) {
  return new Promise(function (resolve, reject) {
    // 一段耗时的异步操作
    request({
      url: "/finance/api/v1/channels/AdminInfo",
      method: "GET",
      params: params
    }).then(function (res) {
      res.data.entities.forEach(function (element) {
        element.spreadProducts = element.spreadProducts.split(",");
      });
      console.log(res);
      resolve(res); // 数据处理完成
    }).catch(function (err) {
      reject(err); // 数据处理出错
    });
  });
} // 新增项目

export function createReq(data) {
  data.spreadProducts = data.spreadProducts.join(",");
  return request({
    url: "/finance/api/v1/channels",
    method: "POST",
    data: data
  });
} // 修改项目

export function updateReq(data) {
  data.spreadProducts = data.spreadProducts.join(",");
  return request("/finance/api/v1/channels", {
    method: "PUT",
    data: data
  });
} // 删除项目

export function deleteReq(projectId) {
  return request({
    url: "/finance/api/v1/channels/".concat(projectId),
    method: "DELETE"
  });
} // 删除项目

export function deleteBatchReq(data) {
  return request({
    url: "/finance/api/v1/channels",
    method: "DELETE",
    data: data
  });
} // 根据项目id查询详情

export function detailReq(projectId) {
  return request({
    // url: `/finance/api/v1/channels/info/${projectId}`,
    method: "GET"
  });
}