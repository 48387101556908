//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { fieldSets, fieldUpdate } from "../api";
var columns = [{
  title: "字段名称",
  dataIndex: "name",
  key: "name",
  scopedSlots: {
    customRender: "name"
  }
}, {
  title: "是否必填",
  dataIndex: "required",
  key: "required",
  scopedSlots: {
    customRender: "required"
  }
}, {
  title: "是否开启",
  dataIndex: "enable",
  key: "enable",
  scopedSlots: {
    customRender: "enable"
  }
}, {
  title: "是否编辑",
  key: "redact",
  dataIndex: "redact",
  scopedSlots: {
    customRender: "redact"
  }
}, {
  title: "是否用于规则",
  key: "rule",
  dataIndex: "rule",
  scopedSlots: {
    customRender: "rule"
  }
}, {
  title: "类型",
  key: "ruleTypes",
  dataIndex: "ruleTypes",
  scopedSlots: {
    customRender: "ruleTypes"
  }
}, {
  title: "规则类型",
  key: "ruleType",
  dataIndex: "ruleType",
  scopedSlots: {
    customRender: "ruleType"
  }
}];
export default {
  data: function data() {
    var _this = this;

    return {
      data: [],
      columns: columns,
      fieldName: "",
      list: [{
        name: "范围",
        id: 1
      }, {
        name: "枚举",
        id: 2
      }],
      paginationOpt: {
        defaultCurrent: 1,
        // 默认当前页数
        defaultPageSize: 10,
        // 默认当前页显示数据的大小
        total: 0,
        // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["10", "20", "50"],
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        // 显示总数
        onShowSizeChange: function onShowSizeChange(current, pageSize) {
          _this.paginationOpt.defaultCurrent = 1;
          _this.paginationOpt.defaultPageSize = pageSize;

          _this.dataList(); // 显示列表的接口名称

        },
        // 改变每页数量时更新显示
        onChange: function onChange(current, size) {
          _this.paginationOpt.defaultCurrent = current;
          _this.paginationOpt.defaultPageSize = size;

          _this.dataList();
        }
      }
    };
  },
  created: function created() {
    this.dataList();
  },
  methods: {
    // 获取列表
    dataList: function dataList() {
      var _this2 = this;

      fieldSets({
        fieldName: this.fieldName
      }).then(function (res) {
        _this2.data = res.data.entities;
        _this2.total = res.data.entityCount;
      });
    },
    // 是否必填
    onChange: function onChange(e, record) {
      var _this3 = this;

      fieldUpdate({
        id: record.id,
        required: e.target.checked,
        fieldId: record.fieldId
      }).then(function (res) {
        if (res.code === 200) {
          _this3.$message.success("修改成功");

          _this3.dataList();
        }
      });
    },
    // 是否开启
    onChange1: function onChange1(e, record) {
      var _this4 = this;

      fieldUpdate({
        id: record.id,
        enable: e.target.checked,
        fieldId: record.fieldId
      }).then(function (res) {
        if (res.code === 200) {
          _this4.$message.success("修改成功");

          _this4.dataList();
        }
      });
    },
    // 是否编辑
    onChange2: function onChange2(e, record) {
      var _this5 = this;

      fieldUpdate({
        id: record.id,
        redact: e.target.checked,
        fieldId: record.fieldId
      }).then(function (res) {
        if (res.code === 200) {
          _this5.$message.success("修改成功");

          _this5.dataList();
        }
      });
    },
    // 是否用于规则
    onChange3: function onChange3(e, record) {
      var _this6 = this;

      fieldUpdate({
        id: record.id,
        rule: e.target.checked,
        fieldId: record.fieldId
      }).then(function (res) {
        if (res.code === 200) {
          _this6.$message.success("修改成功");

          _this6.dataList();
        }
      });
    },
    // 选择类型
    dropLink: function dropLink(item, record) {
      var _this7 = this;

      fieldUpdate({
        id: record.id,
        ruleType: item.id,
        fieldId: record.fieldId
      }).then(function (res) {
        if (res.code === 200) {
          _this7.$message.success("修改成功");

          _this7.dataList();
        }
      });
    },
    serchEnter: function serchEnter() {
      this.dataList();
    }
  }
};