import request from "@/utils/request"; // 查询意向客户统计信息

export function getStatistics(data) {
  return request({
    url: "/finance/api/v1/intentionCustomers/getStatistics",
    method: "GET",
    params: data
  });
} // ***************************************员工推广
// 获取当前渠道的产品

export function getChannelProduct(data) {
  return request({
    url: "/finance/api/v1/channelInfos/getChannelProduct",
    method: "GET",
    params: data
  });
} // 分页查询员工推广信息

export function getEmpChannelInPage(data) {
  return request({
    url: "/finance/api/v1/channelInfos/getEmpChannelInPage",
    method: "get",
    params: data
  });
} // 查询渠道信息

export function channelInfolistAll(data) {
  return request({
    url: "/finance/api/v1/channelInfos/listAll",
    method: "GET",
    params: data
  });
} // 新增渠道信息

export function addStaffPromotion(data) {
  return request({
    url: "/finance/api/v1/channelInfos/saveEmpChannel",
    method: "post",
    data: data
  });
} // 重新生成推广链接

export function updateLink(channelInfoId) {
  return request({
    url: "/finance/api/v1/channelInfos/updateChannelLink/".concat(channelInfoId),
    method: "put"
  });
}
export function updateChannelStatus(data) {
  return request({
    url: "/finance/api/v1/channelInfos/updateChannelStatus",
    method: "put",
    data: data
  });
} // 批量修改渠道二维码背景模板

export function batchUpdateQrCodeBg(data) {
  return request({
    url: "/finance/api/v1/channelInfos/updateQrCodeBackGround",
    method: "put",
    data: data
  });
} // 批量删除

export function deleteChannels(data) {
  return request({
    url: "/finance/api/v1/channelInfos",
    method: "DELETE",
    data: data
  });
} // ***************************************渠道推广
// 分页查询渠道信息
// url: "/finance/api/v1/channelInfos", 改2020.3.6

export function getChannelInfos(data) {
  return request({
    url: "/finance/api/v1/channelInfos/getChannelInPage",
    method: "GET",
    params: data
  });
} // 新增渠道信息

export function addChannelPromotion(data) {
  return request({
    url: "/finance/api/v1/channelInfos",
    method: "POST",
    data: data
  });
} // 修改渠道信息

export function editChannelPromotion(data) {
  return request({
    url: "/finance/api/v1/channelInfos",
    method: "put",
    data: data
  });
} // 分页查询意向客户表

export function getIntentionCustomers(data) {
  return request({
    url: "/finance/api/v1/intentionCustomers",
    method: "GET",
    params: data
  });
} // 添加回访记录

export function addReturnVisitRecord(data) {
  return request({
    url: "/finance/api/v1/intentionCustomers/addReturnVisitRecord",
    method: "PUT",
    data: data
  });
} // 根据渠道信息id查询渠道信息详情

export function getChannelDetailById(channelInfoId) {
  return request({
    url: "/finance/api/v1/channelInfos/".concat(channelInfoId),
    method: "GET"
  });
} // 二维码

export function getChannelQrCode(data) {
  return request({
    url: "/finance/api/v1/channelInfos/getChannelQrCode",
    method: "GET",
    params: data
  });
} // 查询站点配置信息  h5模板

export function siteConfigs(data) {
  return request({
    url: "user/api/v1/userSites/listAll?source=".concat(process.env.VUE_APP_SOURCE),
    method: "GET",
    params: data
  });
} // 修改租户配置

export function updateSiteConfig(data) {
  return request({
    url: "/finance/api/v1/templates",
    method: "put",
    data: data
  });
}