import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.find";
import "core-js/modules/es.array.find-index";
import "core-js/modules/es.array.for-each";
import "core-js/modules/es.array.map";
import "core-js/modules/es.object.keys";
import "core-js/modules/web.dom-collections.for-each";
import "core-js/modules/web.timers";
import _objectSpread from "/data/jenkins/workspace/prod-e-retail/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { formatDate } from "./date";
import newApproval from "./common/new-approval";
import { allBySite, listUserApprosApprovedByReceiver, userApproDetails, pass, reject, receiver, getFlowProcessByApproInfo, allGroupAndFlowByCompanyUser, orderInfos } from "./api";
import { mapState } from "vuex";
import defaultAvatar from "./assets/icons/person1.png";
import defaultAvatarBack from "./assets/icons/person2.png";
import defaultAvatarDone from "./assets/icons/person3.png";
import defaultAvatarWait from "./assets/icons/person4.png";
import arrowUp from "./assets/icons/sjup.png";
import arrowDown from "./assets/icons/sjdown.png";
export default {
  name: "",
  inject: ["reload"],
  components: {
    newApproval: newApproval
  },
  props: {},
  data: function data() {
    return {
      defaultAvatar: defaultAvatar,
      defaultAvatarBack: defaultAvatarBack,
      defaultAvatarDone: defaultAvatarDone,
      defaultAvatarWait: defaultAvatarWait,
      arrowUp: arrowUp,
      arrowDown: arrowDown,
      defaultSelectedKeys: [],
      ApprovalTitle: "审批详情",
      pagination: {
        pageNo: 1,
        pageSize: 10,
        showQuickJumper: true,
        total: 10
      },
      confirmAlerts: false,
      statusData: {},
      showStatus: false,
      jsonData: {
        list: [],
        config: {
          layout: "horizontal",
          labelCol: {
            span: 4
          },
          wrapperCol: {
            span: 18
          },
          hideRequiredMark: false,
          customStyle: ""
        }
      },
      userName: "",
      remarkForm: this.$form.createForm(this, {
        name: "coordinated"
      }),
      approvalUserDetail: [],
      notifierlist: [],
      params: {
        status: null,
        approvId: null,
        creatUserId: null,
        gmtCreateStart: null,
        gmtCreateEnd: null
      },
      listNumAll: 0,
      userApproverId: "",
      // 审批操作参数
      showApprovaler: null,
      approvalHandle: null,
      // 审批操作同意或拒绝
      showApprovalHandle: false,
      confirmAlert: false,
      confirmLoading: false,
      // companyId: this.$store.state.hr.companyId,

      /* 新建审批 */
      choseUser: "",
      types: "",
      createApp: false,
      data: [],
      approvalType: [],
      items: [],
      flowId: "",
      userApproId: "",
      itemTypes: []
    };
  },
  computed: _objectSpread({}, mapState(["hr"])),
  watch: {},
  created: function created() {
    this.getApprovalList();
    this.getAllBySite();
  },
  mounted: function mounted() {},
  methods: {
    // 获取所有审批 bysite
    getAllBySite: function getAllBySite() {
      var _this = this;

      var companyId = localStorage.getItem("lesseeId");
      allGroupAndFlowByCompanyUser("".concat(companyId), "".concat(process.env.VUE_APP_SUBJECT_TYPE)).then(function (res) {
        _this.items = res.data;
        var listNum = 0;

        _this.items.forEach(function (item) {
          item.key = item.id;
          item.title = item.groupName;
          listNum += item.waitTaskNum;
        });

        _this.listNumAll = listNum;
      }).catch(function (err) {
        return console.warn(err);
      });
    },
    // 获取审批列表
    getApprovalList: function getApprovalList() {
      var _this2 = this;

      listUserApprosApprovedByReceiver(_objectSpread(_objectSpread(_objectSpread({}, this.params), this.pagination), {}, {
        moduleType: process.env.VUE_APP_SUBJECT_TYPE,
        hasAppro: false
      })).then(function (res) {
        _this2.data = res.data.entities;
        _this2.pagination.total = res.data.entityCount;
      });
    },
    // 查看审批节点
    statusDetail: function statusDetail(record) {
      var _this3 = this;

      this.ApprovalTitle = record.remark ? record.remark : "审批详情";
      this.showStatus = true;
      this.showApprovalHandle = false;
      userApproDetails(record.userApproId).then(function (res) {
        if (res) {
          _this3.statusData = res.data;
          _this3.userApproverId = record.userApproverId;
          _this3.statusData.approName = record.approName;
          _this3.approvalUserDetail = res.data.userApproInfoList;
          _this3.jsonData.list = _this3.transSourceToJson(res.data.userApproInfoList);
          _this3.notifierlist = res.data.userApproNodeList.find(function (item) {
            return item.nodeType === "notifier";
          }) ? res.data.userApproNodeList.find(function (item) {
            return item.nodeType === "notifier";
          }) : [];
        }
      });
    },
    // 操作审批节点
    editorData: function editorData(record) {
      var _this4 = this;

      this.ApprovalTitle = record.remark ? record.remark : "审批详情";
      setTimeout(function () {
        _this4.remarkForm.setFieldsValue({
          remark: ""
        });
      });
      this.showStatus = true;
      this.showApprovalHandle = true;
      userApproDetails(record.userApproId).then(function (res) {
        console.log(res, 777);
        _this4.userName = res.data.userName;
        _this4.statusData = res.data;
        _this4.userApproverId = record.userApproverId;
        _this4.itemDetail = res.data.userApproInfoList;
        _this4.jsonData.list = _this4.transSourceToJson(_this4.itemDetail);
        _this4.statusData.approName = record.approName;
        _this4.approvalUserDetail = res.data.userApproInfoList;
        _this4.notifierlist = res.data.userApproNodeList.find(function (item) {
          return item.nodeType === "notifier";
        }) ? res.data.userApproNodeList.find(function (item) {
          return item.nodeType === "notifier";
        }) : [];
      });
    },
    // 审批组
    subMenuGroup: function subMenuGroup(item) {
      this.params = {}; // this.params.approvId = null

      this.itemTypes = item.approFlowList;
      this.params.approvGroupId = item.id;
      this.getApprovalList();
    },
    menuItem: function menuItem(item) {
      this.params = {}; // this.params.approvGroupId = null

      this.params.approvId = item.id;
      this.getApprovalList();
    },
    onChangeUser: function onChangeUser(v) {
      this.params.creatUserId = v;
      this.getApprovalList();
    },
    // 领办
    setting: function setting(record) {
      this.confirmAlerts = true;
      this.flowId = record.userApproNodeInfoVO.flowId;
      this.userApproId = record.userApproNodeInfoVO.userApproId;
    },
    confirmHandles: function confirmHandles() {
      var _this5 = this;

      receiver({
        flowId: this.flowId,
        userApproId: this.userApproId
      }).then(function (res) {
        if (res.code === 200) {
          _this5.$notification["success"]({
            message: "领办成功"
          });

          _this5.getApprovalList();

          _this5.confirmAlerts = false;
        } else {
          _this5.$notification["warning"]({
            message: "领办失败"
          });
        }
      });
    },
    // 审批通过
    agree: function agree() {
      var _this6 = this;

      this.remarkForm.validateFields(function (err, values) {
        if (!err) {
          _this6.confirmAlert = true;
          _this6.approvalHandle = true;
        } else {
          _this6.$notification["warning"]({
            message: "请填写审批意见"
          });
        }
      });
    },
    confirmHandle: function confirmHandle() {
      if (this.approvalHandle === true) {
        this.onSure();
      } else {
        this.onBack();
      }
    },
    // 通过审批
    onSure: function onSure() {
      var _this7 = this;

      this.$refs.kfd.getData().then(function (values) {
        var keys = Object.keys(values);

        var userApproInfoList = _this7.itemDetail.filter(function (val) {
          return val.settingType === "edit";
        });

        keys.map(function (key) {
          var idx = userApproInfoList.findIndex(function (item) {
            return item.formId === key;
          });

          if (idx > -1) {
            userApproInfoList[idx]["value"] = values[key];
          }
        });

        _this7.remarkForm.validateFields(function (err, values) {
          if (!err) {
            pass(_this7.userApproverId, {
              remark: values.remark,
              userApproInfoList: userApproInfoList
            }).then(function (res) {
              if (res.success) {
                _this7.showStatus = false;

                _this7.$notification["success"]({
                  message: "审批通过"
                });

                _this7.reload();
              }
            });
          }
        });
      });
    },
    // 拒接
    reject: function reject() {
      var _this8 = this;

      this.remarkForm.validateFields(function (err, values) {
        if (!err) {
          _this8.confirmAlert = true;
          _this8.approvalHandle = false;
        } else {
          _this8.$notification["warning"]({
            message: "请填写审批意见"
          });
        }
      });
    },
    // 驳回审批
    onBack: function onBack() {
      var _this9 = this;

      this.$refs.kfd.getData().then(function (values) {
        var keys = Object.keys(values);

        var userApproInfoList = _this9.itemDetail.filter(function (val) {
          return val.settingType === "edit";
        });

        keys.map(function (key) {
          var idx = userApproInfoList.findIndex(function (item) {
            return item.formId === key;
          });

          if (idx > -1) {
            userApproInfoList[idx]["value"] = values[key];
          }
        });

        _this9.remarkForm.validateFields(function (err, values) {
          if (!err) {
            reject(_this9.userApproverId, {
              remark: values.remark,
              userApproInfoList: userApproInfoList
            }).then(function (res) {
              if (res.success) {
                _this9.showStatus = false;

                _this9.$notification["success"]({
                  message: "审批驳回"
                });

                _this9.reload();
              }
            });
          }
        });
      });
    },
    handleTableChange: function handleTableChange(pagination, filters, sorter) {
      this.pagination.pageNo = pagination.current;
      this.getApprovalList();
    },
    showPeople: function showPeople(idx) {
      this.showApprovaler = this.showApprovaler === idx ? null : idx;
    },
    // 日期选择
    onChange: function onChange(date, dateString) {
      this.params.gmtCreateStart = null;
      this.params.gmtCreateEnd = null;

      if (date.length) {
        this.params.gmtCreateStart = date[0]._d.getTime();
        this.params.gmtCreateEnd = date[1]._d.getTime();
      }

      this.getApprovalList();
    },
    transSourceToJson: function transSourceToJson(data) {
      var list = [];
      data.map(function (item) {
        if (item.props && item.props.length > 0) {
          var obj = JSON.parse(item.props);
          obj["options"]["defaultValue"] = item.value;
          obj["options"]["disabled"] = item.settingType !== "edit";
          obj["options"]["hidden"] = item.settingType === "hide";
          list.push(obj);
        }
      });
      return list;
    },
    getStatusTitle: function getStatusTitle(status) {
      // 0:待审批1:审批中2:通过3:驳回4:撤回5:其他人通过6其他人驳回
      if (status === 0) {
        return "待审批";
      } else if (status === 1) {
        return "审批中";
      } else if (status === 2) {
        return "已通过";
      } else if (status === 3) {
        return "已驳回";
      } else if (status === 4) {
        return "已撤回";
      } else if (status === 5) {
        return "其他人通过";
      } else if (status === 6) {
        return "其他人驳回";
      }

      return "其他";
    },
    getActivateType: function getActivateType(type) {
      // 0:依次审批;1:会签(需所有审批人同意);2:或签(一名审批人同意或拒绝即可)
      if (type === 0) {
        return "依次审批";
      } else if (type === 1) {
        return "会签";
      } else if (type === 2) {
        return "或签";
      }

      return "其他";
    }
  }
};