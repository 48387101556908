import _objectSpread from "/data/jenkins/workspace/prod-e-retail/node_modules/@babel/runtime/helpers/esm/objectSpread2";
import request from "@/utils/request"; // 分页查询员工推广信息

export function spreadsy(params) {
  return request({
    url: "/finance/api/v1/spreads",
    method: "GET",
    params: params
  });
} // 分页查询员工推广信息

export function spreads(params) {
  return request({
    url: "/finance/api/v1/spreads/me",
    method: "GET",
    params: params
  });
} // 分页查询员工推广信息

export function spreadsStatistics(params) {
  return request({
    url: "/finance/api/v1/spreads/statistics",
    method: "GET",
    params: params
  });
} // 批量删除

export function deleteSpreads(data) {
  return request({
    url: "/finance/api/v1/spreads",
    method: "DELETE",
    data: data
  });
} // 其他新增渠道信息

export function general(data) {
  return request({
    url: "/finance/api/v1/spreads/general",
    method: "post",
    data: data
  });
} // 银行新增渠道信息

export function generalBank(data) {
  return request({
    url: "/finance/api/v1/spreads",
    method: "post",
    data: _objectSpread(_objectSpread({}, data), {}, {
      channelId: localStorage.getItem("lesseeId")
    })
  });
} // 修改渠道信息

export function updataSpreads(data) {
  return request({
    url: "/finance/api/v1/spreads",
    method: "put",
    data: data
  });
}
export function updateChannelStatus(data) {
  return request({
    url: "/finance/api/v1/channelInfos/updateChannelStatus",
    method: "put",
    data: data
  });
} // 重新生成推广链接

export function updateLink(channelInfoId) {
  return request({
    url: "/finance/api/v1/channelInfos/updateChannelLink/".concat(channelInfoId),
    method: "put"
  });
} // 查询产品模板和二维码模板

export function getTemplateList(data) {
  return request({
    url: "/finance/api/v1/templates/listAll",
    method: "GET",
    params: _objectSpread(_objectSpread({}, data), {}, {
      channelId: localStorage.getItem("lesseeId")
    })
  });
} // 批量修改渠道二维码背景模板

export function batchUpdateQrCodeBg(data) {
  return request({
    url: "/finance/api/v1/channelInfos/updateQrCodeBackGround",
    method: "put",
    data: data
  });
}
export function deleteReq(spreadId) {
  return request({
    url: "/finance/api/v1/spreads/".concat(spreadId),
    method: "delete"
  });
}