var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "pSlide" },
    [
      _c(
        "a-layout",
        [
          _c(
            "a-layout-sider",
            { attrs: { width: 225 } },
            [
              _c(
                "div",
                {
                  staticClass: "pSlide-title pd_l16 ",
                  on: {
                    mouseenter: function($event) {
                      _vm.addIcon = true
                    },
                    mouseleave: function($event) {
                      _vm.addIcon = false
                    },
                    click: function($event) {
                      $event.stopPropagation()
                      return _vm.getAllList($event)
                    }
                  }
                },
                [
                  _vm._v(" 审批分组名称 "),
                  _c("a-icon", {
                    staticClass: "mg_l81",
                    staticStyle: { "font-weight": "500" },
                    attrs: { type: "plus" },
                    on: {
                      click: function($event) {
                        $event.stopPropagation()
                        return _vm.newGroup($event)
                      }
                    }
                  })
                ],
                1
              ),
              _c(
                "a-menu",
                { staticStyle: { height: "calc(100vh - 250px)" } },
                _vm._l(_vm.groupList, function(item, index) {
                  return _c(
                    "a-menu-item",
                    {
                      key: index,
                      staticClass: "menu",
                      class: { active: item.id == _vm.num },
                      on: {
                        mouseenter: function($event) {
                          _vm.act = item.id
                        },
                        mouseleave: function($event) {
                          _vm.act = false
                        },
                        click: function($event) {
                          return _vm.getApprovalList(item)
                        }
                      }
                    },
                    [
                      _c(
                        "a-row",
                        {
                          attrs: {
                            type: "flex",
                            align: "middle",
                            justify: "space-between"
                          }
                        },
                        [
                          _c(
                            "a-col",
                            { staticClass: "ove", attrs: { span: 20 } },
                            [
                              _c(
                                "a-tooltip",
                                { attrs: { placement: "rightTop" } },
                                [
                                  _c("template", { slot: "title" }, [
                                    _c("span", [
                                      _vm._v(_vm._s("" + item.groupName))
                                    ])
                                  ]),
                                  _c("span", [
                                    _vm._v(_vm._s("" + item.groupName))
                                  ])
                                ],
                                2
                              )
                            ],
                            1
                          ),
                          _c(
                            "a-col",
                            [
                              _c("a-icon", {
                                attrs: { type: "setting" },
                                on: {
                                  click: function($event) {
                                    $event.stopPropagation()
                                    return _vm.setting(item)
                                  }
                                }
                              })
                            ],
                            1
                          )
                        ],
                        1
                      )
                    ],
                    1
                  )
                }),
                1
              )
            ],
            1
          ),
          _c(
            "a-layout",
            [
              _c(
                "a-row",
                { staticClass: "lay-head", attrs: { type: "flex" } },
                [
                  _c(
                    "a-col",
                    [
                      _c(
                        "a-button",
                        {
                          attrs: { type: "primary", icon: "plus" },
                          on: {
                            click: function($event) {
                              return _vm.createApproval(null)
                            }
                          }
                        },
                        [_vm._v(" 新建审批 ")]
                      )
                    ],
                    1
                  ),
                  _c(
                    "a-col",
                    { staticClass: "mg_l_o" },
                    [
                      _c("a-input-search", {
                        staticStyle: { width: "200px" },
                        attrs: { placeholder: "搜索" },
                        on: { search: _vm.onSearch }
                      })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "a-layout-content",
                [
                  _c(
                    "a-table",
                    {
                      staticStyle: {
                        "margin-top": "20px",
                        "margin-left": "20px"
                      },
                      attrs: {
                        "data-source": _vm.data,
                        rowKey: function(data) {
                          return data.id
                        }
                      }
                    },
                    [
                      _c("a-table-column", {
                        attrs: { title: "审批列表" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(text) {
                              return [
                                _c(
                                  "a-row",
                                  { attrs: { type: "flex", align: "middle" } },
                                  [
                                    _c(
                                      "a-col",
                                      [
                                        _c("a-row", { staticClass: "title1" }, [
                                          _vm._v(" " + _vm._s(text.typeName))
                                        ]),
                                        _c(
                                          "a-row",
                                          { staticClass: "subTitle" },
                                          [_vm._v(_vm._s(text.typeDesc))]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ])
                      }),
                      _c("a-table-column", {
                        attrs: { title: "操作", width: 300 },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function(text) {
                              return [
                                _c(
                                  "span",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    on: {
                                      click: function($event) {
                                        return _vm.createApproval(text.id)
                                      }
                                    }
                                  },
                                  [_vm._v(" 编辑 ")]
                                ),
                                _c("a-divider", {
                                  attrs: { type: "vertical" }
                                }),
                                _c(
                                  "span",
                                  {
                                    staticStyle: { cursor: "pointer" },
                                    attrs: { type: "small" },
                                    on: {
                                      click: function($event) {
                                        return _vm.deleteUseItem(text)
                                      }
                                    }
                                  },
                                  [_vm._v(" 删除 ")]
                                ),
                                _c("a-switch", {
                                  staticStyle: {
                                    "border-radius": "4px",
                                    "margin-left": "24px",
                                    "margin-boottom": "-5px",
                                    background: "#1890ff"
                                  },
                                  attrs: {
                                    checkedChildren: "已启用",
                                    unCheckedChildren: "未启用"
                                  },
                                  on: {
                                    change: function($event) {
                                      return _vm.onChangeUse($event, text)
                                    }
                                  },
                                  model: {
                                    value: text.inUse,
                                    callback: function($$v) {
                                      _vm.$set(text, "inUse", $$v)
                                    },
                                    expression: "text.inUse"
                                  }
                                })
                              ]
                            }
                          }
                        ])
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _c("new-group", { attrs: { newGroupOptions: _vm.newGroupOptions } })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }