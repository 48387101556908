var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("div", { staticClass: "herder" }, [
        _c("div", { staticClass: "herder-center" }, [
          _c("span", { staticClass: "herder-center-title" }, [
            _vm._v("总人数")
          ]),
          _c("span", { staticClass: "herder-center-num" }, [
            _vm._v(_vm._s(_vm.statisticsList.headcount) + " 人")
          ])
        ]),
        _c("div", { staticClass: "herder-center" }, [
          _c("span", { staticClass: "herder-center-titles" }, [
            _vm._v("意向客户")
          ]),
          _c("span", { staticClass: "herder-center-nums" }, [
            _vm._v(" " + _vm._s(_vm.statisticsList.potentialClients) + " 人 ")
          ])
        ]),
        _c("div", { staticClass: "herder-center" }, [
          _c("span", { staticClass: "herder-center-titles" }, [
            _vm._v("审批中单量")
          ]),
          _c("span", { staticClass: "herder-center-nums" }, [
            _vm._v(" " + _vm._s(_vm.statisticsList.haveApprove) + " 人 ")
          ])
        ]),
        _c("div", { staticClass: "herder-center" }, [
          _c("span", { staticClass: "herder-center-titles" }, [
            _vm._v("审批通过单量")
          ]),
          _c("span", { staticClass: "herder-center-nums" }, [
            _vm._v(" " + _vm._s(_vm.statisticsList.finishApprove) + " 人 ")
          ])
        ]),
        _c("div", { staticClass: "herder-center" }, [
          _c("span", { staticClass: "herder-center-titles" }, [
            _vm._v("拒绝单量")
          ]),
          _c("span", { staticClass: "herder-center-nums" }, [
            _vm._v(" " + _vm._s(_vm.statisticsList.refuseOrder) + " 人 ")
          ])
        ])
      ]),
      _c(
        "a-button",
        {
          staticStyle: { "margin-bottom": "20px" },
          attrs: { type: "primary" },
          on: { click: _vm.showCreateModal }
        },
        [_vm._v(" 新增推广 ")]
      ),
      _c(
        "a-spin",
        { attrs: { spinning: _vm.spinning } },
        [
          _c("a-table", {
            staticStyle: { width: "100%" },
            attrs: {
              expandIcon: _vm.expandIcon,
              columns: _vm.columns,
              "data-source": _vm.tableDatas,
              rowKey: "id",
              pagination: _vm.paginationOpt
            },
            scopedSlots: _vm._u([
              {
                key: "enabled",
                fn: function(text, record) {
                  return _c(
                    "span",
                    {},
                    [
                      _c("a-switch", {
                        attrs: {
                          checkedChildren: "是",
                          unCheckedChildren: "否",
                          defaultChecked: record.enabled
                        },
                        on: {
                          change: function($event) {
                            return _vm.changeUsed($event, record)
                          }
                        }
                      })
                    ],
                    1
                  )
                }
              },
              {
                key: "extendLink",
                fn: function(text) {
                  return _c(
                    "span",
                    {
                      staticStyle: {
                        display: "inline-block",
                        width: "100%",
                        "white-space": "normal",
                        "word-wrap": "break-word"
                      }
                    },
                    [_vm._v(" " + _vm._s(text) + " ")]
                  )
                }
              },
              {
                key: "action",
                fn: function(text, record) {
                  return _c(
                    "span",
                    { attrs: { href: "javascript:;" } },
                    [
                      _c(
                        "a",
                        {
                          directives: [
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:copy",
                              value: _vm.uploadUrl + record.extendLink,
                              expression: "uploadUrl + record.extendLink",
                              arg: "copy"
                            },
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:success",
                              value: _vm.onCopy,
                              expression: "onCopy",
                              arg: "success"
                            },
                            {
                              name: "clipboard",
                              rawName: "v-clipboard:error",
                              value: _vm.onError,
                              expression: "onError",
                              arg: "error"
                            }
                          ]
                        },
                        [_vm._v(" 复制 ")]
                      ),
                      _c("a-divider", { attrs: { type: "vertical" } }),
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.showUpdateModals(record)
                            }
                          }
                        },
                        [_vm._v("编辑")]
                      ),
                      _c("a-divider", { attrs: { type: "vertical" } }),
                      _c(
                        "a",
                        {
                          on: {
                            click: function($event) {
                              return _vm.checkReq(record)
                            }
                          }
                        },
                        [_vm._v("预览")]
                      ),
                      _c("a-divider", { attrs: { type: "vertical" } }),
                      _c(
                        "a-popconfirm",
                        {
                          attrs: {
                            title: "确认要删除记录吗？",
                            okText: "确认",
                            cancelText: "取消"
                          },
                          on: {
                            confirm: function($event) {
                              return _vm.deleteRecord(record)
                            }
                          }
                        },
                        [_c("a", [_vm._v("删除")])]
                      )
                    ],
                    1
                  )
                }
              },
              {
                key: "expandedRowRender",
                fn: function(text) {
                  return _c("div", { staticClass: "nav" }, [
                    _c("div", { staticClass: "herder" }, [
                      _c("div", { staticClass: "herder-center" }, [
                        _c("span", { staticClass: "herder-center-title" }, [
                          _vm._v("总推广数")
                        ]),
                        _c("span", { staticClass: "herder-center-num" }, [
                          _vm._v(
                            " " + _vm._s(text.spreadUser.headcount) + " 人 "
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "herder-center" }, [
                        _c("span", { staticClass: "herder-center-titles" }, [
                          _vm._v("意向客户")
                        ]),
                        _c("span", { staticClass: "herder-center-nums" }, [
                          _vm._v(
                            " " +
                              _vm._s(text.spreadUser.potentialClients) +
                              " 人 "
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "herder-center" }, [
                        _c("span", { staticClass: "herder-center-titles" }, [
                          _vm._v("审批中单量")
                        ]),
                        _c("span", { staticClass: "herder-center-nums" }, [
                          _vm._v(
                            " " + _vm._s(text.spreadUser.haveApprove) + " 人 "
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "herder-center" }, [
                        _c("span", { staticClass: "herder-center-titles" }, [
                          _vm._v("审批通过单量")
                        ]),
                        _c("span", { staticClass: "herder-center-nums" }, [
                          _vm._v(
                            " " + _vm._s(text.spreadUser.finishApprove) + " 人 "
                          )
                        ])
                      ]),
                      _c("div", { staticClass: "herder-center" }, [
                        _c("span", { staticClass: "herder-center-titles" }, [
                          _vm._v("拒绝单量")
                        ]),
                        _c("span", { staticClass: "herder-center-nums" }, [
                          _vm._v(
                            " " + _vm._s(text.spreadUser.refuseOrder) + " 人 "
                          )
                        ])
                      ])
                    ])
                  ])
                }
              }
            ])
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            destroyOnClose: "",
            top: "100px",
            title: "预览",
            visible: _vm.checkVisible
          },
          on: {
            "update:visible": function($event) {
              _vm.checkVisible = $event
            },
            cancel: _vm.cancel
          }
        },
        [
          _c(
            "div",
            {
              staticStyle: {
                width: "300px",
                height: "600px",
                "margin-left": "18%",
                position: "relative"
              },
              attrs: { id: "qrbox" }
            },
            [
              _c("img", {
                staticClass: "imgs",
                staticStyle: { width: "300px", height: "600px" },
                attrs: { src: _vm.recordBackground }
              }),
              _c("div", { staticClass: "qrcodes", attrs: { id: "qrcode" } })
            ]
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              attrs: { slot: "footer" },
              slot: "footer"
            },
            [
              _c("a-button", { on: { click: _vm.cancel } }, [_vm._v("取 消")]),
              _c(
                "a-button",
                {
                  staticClass: "adjust-button",
                  attrs: { type: "primary" },
                  on: { click: _vm.savecanvas }
                },
                [_vm._v(" 下载 ")]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            destroyOnClose: true,
            title: "新增推广",
            width: 800
          },
          on: { cancel: _vm.createCancel, ok: _vm.createOk },
          model: {
            value: _vm.createModalVisible,
            callback: function($$v) {
              _vm.createModalVisible = $$v
            },
            expression: "createModalVisible"
          }
        },
        [
          _c("Extendform", {
            ref: "createForm",
            attrs: {
              dataSource: {},
              dynamicData: _vm.dynamicData,
              formFields: _vm.FormJson["fields"],
              formValidators: _vm.FormJson["validators"],
              formConfig: _vm.FormJson["config"]["createLoanProd"]
            }
          })
        ],
        1
      ),
      _c(
        "a-modal",
        {
          attrs: {
            maskClosable: false,
            destroyOnClose: true,
            title: "修改推广",
            width: 800
          },
          on: { cancel: _vm.updateCancel, ok: _vm.updateOk },
          model: {
            value: _vm.updateModalVisible,
            callback: function($$v) {
              _vm.updateModalVisible = $$v
            },
            expression: "updateModalVisible"
          }
        },
        [
          _c("Extendform", {
            ref: "updateForm",
            attrs: {
              dataSource: _vm.tempRecord,
              dynamicData: _vm.dynamicData,
              formFields: _vm.FormJson["fields"],
              formValidators: _vm.FormJson["validators"],
              formConfig: _vm.FormJson["config"]["updateLoanProd"]
            }
          })
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }