import _objectSpread from "/data/jenkins/workspace/prod-e-retail/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import newGroup from "./new-group";
import { allBySite, allGroupAndFlowByCompanyUser, allByCompany, approFlows, reinstateOne, leaveOne, approFlowsDelete, allByGroup } from "@/api/index";
export default {
  name: "",
  components: {
    newGroup: newGroup
  },
  props: {},
  data: function data() {
    return {
      allList: 0,
      // 添加图标
      addIcon: false,
      // 设置图标
      act: false,
      // companyId: this.$store.state.hr.companyId,
      id: null,
      groupList: [],
      newGroupOptions: null,
      data: [],
      num: "",
      query: {
        groupId: "",
        typeName: ""
      }
    };
  },
  computed: {},
  watch: {},
  created: function created() {},
  mounted: function mounted() {
    this.getGroupList();
    this.getAllList();
  },
  methods: {
    //  获取分组列表
    getGroupList: function getGroupList() {
      var _this = this;

      var companyId = localStorage.getItem("lesseeId");
      allGroupAndFlowByCompanyUser("".concat(companyId), "".concat(process.env.VUE_APP_SUBJECT_TYPE)).then(function (res) {
        _this.groupList = res.data;
      });
    },
    //  新建分组
    newGroup: function newGroup() {
      this.newGroupOptions = {
        show: true,
        obj: {
          title: "新建审批分组",
          subTitle: "新建审批分组名称",
          moduleType: process.env.VUE_APP_SUBJECT_TYPE
        }
      };
    },
    // 设置
    setting: function setting(item) {
      this.newGroupOptions = {
        show: true,
        obj: _objectSpread({
          title: "修改审批分组",
          subTitle: "修改审批分组名称"
        }, item)
      };
    },
    //  创建新审批
    createApproval: function createApproval(id) {
      if (id) {
        this.$router.push({
          path: "/allForm",
          query: {
            id: id
          }
        });
      } else {
        this.$router.push("/allForm");
      }
    },
    // 获取总的审批列表
    getAllList: function getAllList() {
      var _this2 = this;

      this.query = {};
      this.num = "";
      var companyId = localStorage.getItem("lesseeId");
      allByCompany("".concat(companyId), "".concat(process.env.VUE_APP_SUBJECT_TYPE), {
        moduleType: process.env.VUE_APP_SUBJECT_TYPE
      }).then(function (res) {
        _this2.data = res.data;
        _this2.allList = res.data.length;
      });
    },
    // 获取审批列表查询的
    getApprovalList: function getApprovalList(item) {
      var _this3 = this;

      this.num = item.id;
      this.query.groupId = item.id;
      allByGroup(item.id, {
        moduleType: process.env.VUE_APP_SUBJECT_TYPE
      }).then(function (res) {
        _this3.data = res.data;
      });
    },
    // 启用禁用
    onChangeUse: function onChangeUse(val, text) {
      if (val) {
        this.useItem(text);
      } else {
        this.stopUseItem(text);
      }
    },
    // 启用某个审批流程
    useItem: function useItem(item) {
      var _this4 = this;

      reinstateOne(item.id).then(function (data) {
        if (data.code === 200) {
          _this4.$notification["success"]({
            message: "修改成功"
          });
        }
      });
    },
    // 停用某个审批流程
    stopUseItem: function stopUseItem(item) {
      var _this5 = this;

      leaveOne(item.id).then(function (data) {
        if (data.code === 200) {
          _this5.$notification["success"]({
            message: "修改成功"
          });
        }
      });
    },
    // 删除某个审批流程
    deleteUseItem: function deleteUseItem(item) {
      var _this6 = this;

      this.$confirm({
        title: "确定删除?",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk: function onOk() {
          approFlowsDelete(item.id).then(function (data) {
            if (data.success) {
              _this6.$notification["success"]({
                message: "删除成功"
              });

              _this6.getAllList();
            }
          });
        }
      });
    },
    // 搜索
    onSearch: function onSearch(e) {
      var _this7 = this;

      this.query.typeName = e;
      approFlows(_objectSpread(_objectSpread({}, this.query), {}, {
        moduleType: process.env.VUE_APP_SUBJECT_TYPE
      })).then(function (res) {
        _this7.data = res.data;
      });
    }
  }
};