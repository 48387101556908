import "core-js/modules/es.array.join";
import "core-js/modules/es.array.map";
import "core-js/modules/es.regexp.exec";
import "core-js/modules/es.string.split";
import _objectSpread from "/data/jenkins/workspace/prod-e-retail/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
var _columns = [{
  title: "推广名",
  dataIndex: "name",
  key: "name",
  scopedSlots: {
    customRender: "name"
  }
}, {
  title: "推广码链接",
  dataIndex: "extendLink",
  key: "extendLink",
  scopedSlots: {
    customRender: "phone"
  }
}, {
  title: "推广人数",
  dataIndex: "orderNum",
  key: "orderNum",
  scopedSlots: {
    customRender: "orderNum"
  }
}, {
  title: "是否启用",
  dataIndex: "enabled",
  key: "enabled",
  scopedSlots: {
    customRender: "enabled"
  }
}, {
  title: "操作",
  dataIndex: "",
  key: "action",
  scopedSlots: {
    customRender: "action"
  }
}];
import Extendform from "../../Production/productManagement/extendForm";
import FormJson from "../FormJson";
import XqCurdMixin from "xqjr-plugin-form/mixins/XqCurdMixin";
import QRCode from "qrcodejs2";
import html2canvas from "html2canvas";
import { spreads as retrieveReq, generalBank as createReq, updataSpreads as updateReq, spreadsStatistics, deleteReq } from "../api";
var curdMixin = new XqCurdMixin({
  retrieveReq: retrieveReq,
  updateReq: updateReq,
  createReq: createReq,
  deleteReq: deleteReq,
  FormJson: FormJson
});
export default {
  components: {
    Extendform: Extendform
  },
  mixins: [curdMixin],
  data: function data() {
    var _this = this;

    return {
      list: [],
      tableDatas: [],
      num: 0,
      uploadUrl: process.env.VUE_APP_SPREAD,
      checkVisible: false,
      spinning: false,
      statisticsList: [],
      recordBackground: "",
      channelInfoId: "",
      paginationOpt: {
        defaultCurrent: 1,
        // 默认当前页数
        defaultPageSize: 10,
        // 默认当前页显示数据的大小
        total: 0,
        // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["10", "20", "50"],
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        // 显示总数
        onShowSizeChange: function onShowSizeChange(current, pageSize) {
          _this.paginationOpt.defaultCurrent = 1;
          _this.paginationOpt.defaultPageSize = pageSize;

          _this.dataList(); // 显示列表的接口名称

        },
        // 改变每页数量时更新显示
        onChange: function onChange(current, size) {
          _this.paginationOpt.defaultCurrent = current;
          _this.paginationOpt.defaultPageSize = size;

          _this.dataList();
        }
      }
    };
  },
  computed: {
    userId: function userId() {
      return this.$store.getters.userInfo ? this.$store.getters.userInfo.id : null;
    },
    columns: function columns() {
      return _columns;
    },
    retrieveParams: function retrieveParams() {
      return {
        request: {
          userId: this.userId,
          channelId: localStorage.getItem("lesseeId")
        }
      };
    },
    createParams: function createParams() {
      return {
        enabled: 1,
        source: process.env.VUE_APP_SOURCE,
        type: 2,
        userId: this.userId
      };
    }
  },
  watch: {
    userId: {
      handler: function handler(newVal) {
        if (newVal) {
          this.dataList();
          this.statistics();
        }
      },
      immediate: true
    }
  },
  mounted: function mounted() {},
  methods: {
    statistics: function statistics() {
      var _this2 = this;

      spreadsStatistics().then(function (res) {
        if (res.code === 200) {
          console.log(res.data, "res.datares.datares.datares.data");
          _this2.statisticsList = res.data;
        }
      });
    },
    // 获取列表
    dataList: function dataList() {
      var _this3 = this;

      this.spinning = true;
      retrieveReq({
        userId: this.userId,
        channelId: localStorage.getItem("lesseeId")
      }).then(function (res) {
        if (res.code === 200) {
          _this3.tableDatas = res.data.entities;
          _this3.spinning = false;

          _this3.tableDatas.map(function (item) {
            _this3.num += item.orderNum;
          });

          _this3.paginationOpt.total = res.data.entityCount;
        }
      });
    },
    // 是否使用
    changeUsed: function changeUsed(e, record) {
      var _this4 = this;

      updateReq({
        id: record.id,
        enabled: e
      }).then(function (res) {
        _this4.$message.success("操作成功");

        _this4.dataList();
      });
    },
    // 创建
    createOk: function createOk() {
      var _this5 = this;

      this.$refs["createForm"].getData().then(function (values) {
        // 处理特殊返回格式数据
        values.productIds = values.productIds.join(","); // 提交

        _this5.createRecord(values);
      }).catch(function () {
        _this5.$message.error("数据未填写或格式错误");
      });
    },
    // 创建单条记录
    createRecord: function createRecord(values, successDesc, failDesc) {
      var _this6 = this;

      this.toggleSpinning(true);
      createReq(_objectSpread(_objectSpread({}, values), this.createParams)).then(function (res) {
        if (res.success === true) {
          _this6.$message.success(successDesc || "添加成功");

          _this6.dataList();

          _this6.createCancel();
        } else {
          _this6.$message.error(failDesc || "添加失败");
        }
      }).catch(function (err) {
        _this6.$message.error(err.message);
      });
    },
    updateOk: function updateOk() {
      var _this7 = this;

      this.$refs["updateForm"].getData().then(function (values) {
        values.productIds = values.productIds.join(","); // 提交

        _this7.updateRecord(values);
      }).catch(function () {
        _this7.$message.error("数据未填写或格式错误");
      });
    },
    // 更新单条记录
    updateRecord: function updateRecord(values, successDesc, failDesc) {
      var _this8 = this;

      this.toggleSpinning(true);
      updateReq(Object.assign({}, this.tempRecord, values)).then(function (res) {
        if (res.success === true) {
          _this8.$message.success(successDesc || "修改成功");

          _this8.dataList();

          _this8.tempRecord = null;

          _this8.updateCancel();
        } else {
          _this8.$message.error(failDesc || "修改失败");
        }
      }).catch(function (err) {
        _this8.$message.error(err.message);
      });
    },
    showUpdateModals: function showUpdateModals(record) {
      this.tempRecord = record;
      this.updateModalVisible = true;

      if (record.productIds.length > 1) {
        this.tempRecord.productIds = record.productIds.split(",");
      }
    },
    deleteRecord: function deleteRecord(record) {
      var _this9 = this;

      deleteReq(record.id).then(function (res) {
        if (res.code === 200) {
          _this9.dataList();
        }
      });
    },
    // 生成二维码
    qrcode: function qrcode(extendLink, idName) {
      new QRCode(idName, {
        width: 124,
        height: 124,
        // 高度
        text: extendLink // 二维码内容

      });
    },
    // 生成图片和二维码
    checkReq: function checkReq(record) {
      this.recordBackground = record.background;
      this.channelInfoId = record.id;
      this.checkVisible = true;
      this.$nextTick(function () {
        this.qrcode(process.env.VUE_APP_SPREAD + record.extendLink, "qrcode");
      });
    },
    // 下载
    savecanvas: function savecanvas() {
      html2canvas(document.getElementById("qrbox"), {
        useCORS: true,
        allowTaint: false
      }).then(function (canvas) {
        var MIME_TYPE = "image/png";
        var imgURL = canvas.toDataURL("image/png");
        console.log(imgURL, 1111);
        var link = document.createElement("a");
        link.download = "";
        link.href = imgURL;
        link.dataset.downloadurl = [MIME_TYPE, link.download, link.href].join(":");
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      });
    },
    // 复制
    onCopy: function onCopy(e) {
      this.$message.success("复制成功");
    },
    onError: function onError(e) {
      this.$message.success("复制失败");
    },
    // 更换图标
    expandIcon: function expandIcon(props) {
      var h = this.$createElement;

      if (props.record) {
        if (props.expanded) {
          // 有数据-展开时候图标
          return h("a", {
            "style": "color: 'black',margin-right:0px",
            "on": {
              "click": function click(e) {
                props.onExpand(props.record, e);
              }
            }
          }, [h("a-icon", {
            "attrs": {
              "type": "down"
            }
          }), " "]);
        } else {
          // 有数据-未展开时候图标
          return h("a", {
            "style": "color: 'black' ,margin-right:0px",
            "on": {
              "click": function click(e) {
                props.onExpand(props.record, e);
              }
            }
          }, [h("a-icon", {
            "attrs": {
              "type": "right"
            }
          })]);
        }
      }
    },
    // 取消
    cancel: function cancel() {
      this.checkVisible = false;
    }
  }
};