import "core-js/modules/es.array.map";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import XqFormMixin from "xqjr-plugin-form/mixins/XqFormMixin";
import { detailReq, fieldsListAll } from "@/views/Customer/api";
var FormMixin = XqFormMixin();
export default {
  name: "",
  mixins: [FormMixin],
  props: {},
  data: function data() {
    return {
      info: [],
      showSpouseInfo: false,
      baseinfoKeys: ["name", "sex", "idCardType", "idCard", "marriedStatus", "health", "educationLevel", "phone", "phoneSet", "currentAddressId", "currentAddressDetail"],
      data1: ["householdsNum", "houseWorkNumber", "customerIncome", "familyIncome", "extend"],
      customerOccupationVOKeys: ["companyName", "enterprisePosition"],
      spouseInfoVOKeys: ["name", "sex", "idCardType", "idCard", "educationLevel", "yearlyIncome", "currentBankAccount", "health", "companyName", "position", "phone", "extend"],
      operateVOKeys: ["name", "certCheckDate", "type", "operateTime", "people", "registCapital", "registLocation", "realLocation", "operateName", "legalPerson", "operateProduct", "mainProduct", "accountName", "accountBank", "accountNo", "accountBill", "remark", "extend"]
    };
  },
  mounted: function mounted() {
    var _this = this;

    fieldsListAll({
      enable: 1
    }).then(function (res) {
      res.data.map(function (item) {
        _this.info.push(item.fieldVO.englishName);
      });
    });
    detailReq(this.$route.params.id).then(function (res) {
      if (res.data) {
        var marriedStatus = res.data.marriedStatus;

        if (marriedStatus === 2) {
          _this.showSpouseInfo = true;
        } else {
          _this.showSpouseInfo = false;
        }
      }
    });
  },
  methods: {
    onValuesChangeCustom: function onValuesChangeCustom(values) {
      console.log(values, 4444);

      if (values.marriedStatus === 2) {
        this.showSpouseInfo = true;
      } else if (values.marriedStatus === 1 || values.marriedStatus === 3 || values.marriedStatus === 4) {
        this.showSpouseInfo = false;
      }
    }
  }
};