var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c(
        "a-tabs",
        { attrs: { defaultActiveKey: "1" }, on: { change: _vm.callback } },
        _vm._l(_vm.productTypelists, function(type, index) {
          return _c(
            "a-tab-pane",
            { key: index + 1, attrs: { tab: type.productName } },
            [
              index === 0 ? [_c("LoanProd")] : _vm._e(),
              index === 1 ? [_c("DepositProd")] : _vm._e()
            ],
            2
          )
        }),
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }