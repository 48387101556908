import _objectSpread from "/data/jenkins/workspace/prod-e-retail/node_modules/@babel/runtime/helpers/esm/objectSpread2";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { ruleGroupsListAll, fieldSetsListAll, fieldSetsCreate, fieldRules, fieldRulesDelete, fieldSetsUpdate } from "../api";
import newGroup from "./new-groups";
export default {
  components: {
    newGroup: newGroup
  },
  filters: {
    filterType: function filterType(val) {
      if (val === "1") {
        return "初中及以下";
      } else if (val === "2") {
        return "高中及中等专业学校";
      } else if (val === "3") {
        return "大专";
      } else if (val === "4") {
        return "本科";
      } else if (val === "5") {
        return "硕士";
      } else if (val === "6") {
        return "博士及以上";
      } else {
        return "";
      }
    }
  },
  data: function data() {
    var _this = this;

    return {
      data: [],
      newGroupOptions: null,
      visible: false,
      groupList: [],
      fieldList: [],
      type: 0,
      id: "",
      title: "",
      fieldName: "",
      list: [{
        name: "范围",
        id: 1
      }, {
        name: "枚举",
        id: 2
      }],
      evelList: [{
        name: "初中及以下",
        id: "1"
      }, {
        name: "高中及中等专业学校",
        id: "2"
      }, {
        name: "大专",
        id: "3"
      }, {
        name: "本科",
        id: "4"
      }, {
        name: "硕士",
        id: "5"
      }, {
        name: "博士及以上",
        id: "6"
      }],
      paginationOpt: {
        defaultCurrent: 1,
        // 默认当前页数
        defaultPageSize: 10,
        // 默认当前页显示数据的大小
        total: 0,
        // 总数，必须先有
        showSizeChanger: true,
        showQuickJumper: true,
        pageSizeOptions: ["10", "20", "50"],
        showTotal: function showTotal(total) {
          return "\u5171 ".concat(total, " \u6761");
        },
        // 显示总数
        onShowSizeChange: function onShowSizeChange(current, pageSize) {
          _this.paginationOpt.defaultCurrent = 1;
          _this.paginationOpt.defaultPageSize = pageSize;

          _this.getAllList(); // 显示列表的接口名称

        },
        // 改变每页数量时更新显示
        onChange: function onChange(current, size) {
          _this.paginationOpt.defaultCurrent = current;
          _this.paginationOpt.defaultPageSize = size;

          _this.getAllList();
        }
      },
      form: this.$form.createForm(this, {
        name: "coordinated"
      })
    };
  },
  created: function created() {
    this.group();
    this.field();
    this.getAllList();
  },
  methods: {
    // 获取总的列表
    getAllList: function getAllList() {
      var _this2 = this;

      fieldRules({
        fieldName: this.fieldName
      }).then(function (res) {
        if (res.code === 200) {
          _this2.data = res.data.entities;
        }
      });
    },
    // 获取每个产品下的列表
    getApprovalList: function getApprovalList(item) {
      var _this3 = this;

      fieldRules({
        ruleGroupId: item.id
      }).then(function (res) {
        if (res.code === 200) {
          _this3.data = res.data.entities;
        }
      });
    },
    // 获取分组列表
    group: function group() {
      var _this4 = this;

      ruleGroupsListAll().then(function (res) {
        _this4.groupList = res.data;
      });
    },
    // 查询字段设置
    field: function field() {
      var _this5 = this;

      fieldSetsListAll().then(function (res) {
        if (res.code === 200) {
          _this5.fieldList = res.data;
        }
      });
    },
    // 新增规则
    createApproval: function createApproval() {
      this.visible = true;
      this.title = "新增";
      this.type = 0;
      this.form.resetFields();
    },
    handleSelectChange: function handleSelectChange(value) {
      this.type = value;
    },
    // 新增字段
    handleOk: function handleOk() {
      var _this6 = this;

      this.form.validateFields(function (err, values) {
        if (!err) {
          fieldSetsCreate(values).then(function (res) {
            if (res.code === 200) {
              _this6.$message.success("新增成功");

              _this6.getAllList();

              _this6.visible = false;
            }
          });
        }
      });
    },
    // 编辑字段
    updateRules: function updateRules(text) {
      var _this7 = this;

      this.visible = true;
      this.title = "编辑";
      this.id = text.id;
      this.type = text.type;
      this.$nextTick(function () {
        _this7.form.setFieldsValue(Object.assign({}, text));
      });
    },
    handleUpdate: function handleUpdate() {
      var _this8 = this;

      this.form.validateFields(function (err, values) {
        if (!err) {
          values.id = _this8.id;
          fieldSetsUpdate(values).then(function (res) {
            if (res.code === 200) {
              _this8.$message.success("修改成功");

              _this8.getAllList();

              _this8.visible = false;
            }
          });
        }
      });
    },
    // 删除字段
    deleteFieldRules: function deleteFieldRules(text) {
      var _this9 = this;

      this.$confirm({
        title: "确定删除?",
        okText: "确定",
        okType: "danger",
        cancelText: "取消",
        onOk: function onOk() {
          fieldRulesDelete(text.id).then(function (res) {
            if (res.code === 200) {
              _this9.$notification["success"]({
                message: "修改成功"
              });

              _this9.getAllList();
            }
          });
        }
      });
    },
    // 搜索
    onSearch: function onSearch() {
      this.getAllList();
    },
    // 添加分组弹窗
    newGroup: function newGroup() {
      this.newGroupOptions = {
        show: true,
        obj: {
          title: "新建产品规则组",
          subTitle: "新建产品规则组名称",
          moduleType: process.env.VUE_APP_SUBJECT_TYPE
        }
      };
    },
    // 打开每个产品的设置
    setting: function setting(item) {
      this.newGroupOptions = {
        show: true,
        obj: _objectSpread({
          title: "修改产品规则组",
          subTitle: "修改产品规则组名称"
        }, item)
      };
    }
  }
};