//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
import { listUserApprosApprovedByReceiver, listUserApprosApprovedByReceiverMe } from "./api";
import myApproval from "./approval";
import myProcessed from "./processed";
import mySetting from "./setting";
import orderList from "./orderList";
export default {
  inject: ["reload"],
  name: "",
  components: {
    myApproval: myApproval,
    myProcessed: myProcessed,
    mySetting: mySetting,
    orderList: orderList
  },
  props: {},
  data: function data() {
    return {
      activekey: null,
      currentKey: 1,
      countNum: 0,
      countNum1: 0
    };
  },
  computed: {
    status: function status() {
      if (this.$route.query.status === "1") {
        this.dataList();
        this.rootList();
        return "2";
      } else if (this.$route.query.status === "2") {
        return "3";
      }

      return "1";
    }
  },
  watch: {},
  created: function created() {
    this.dataList();
    this.rootList();
  },
  methods: {
    confirmHandlesList: function confirmHandlesList() {
      this.dataList();
      this.rootList();
    },
    dataList: function dataList() {
      var _this = this;

      listUserApprosApprovedByReceiver({
        moduleType: process.env.VUE_APP_SUBJECT_TYPE,
        hasAppro: false
      }).then(function (res) {
        _this.countNum = res.data.entityCount;
      });
    },
    rootList: function rootList() {
      var _this2 = this;

      listUserApprosApprovedByReceiverMe({
        moduleType: process.env.VUE_APP_SUBJECT_TYPE,
        hasAppro: false
      }).then(function (res) {
        _this2.countNum1 = res.data.entityCount;
      });
    },
    callback: function callback(e) {
      var _this3 = this;

      if (e === "2") {
        this.$nextTick(function () {
          _this3.$refs.setting.retrieveRecord();
        });
      } else if (e === "3") {
        this.$nextTick(function () {
          _this3.$refs.processed.retrieveRecord();
        });
      }
    }
  }
};