import request from "@/utils/request";
export function retrieveReq(params) {
  return request({
    url: "/finance/api/v1/housingValuations",
    method: "GET",
    params: params
  });
} // 查询订单信息带房屋估值和风控查询详情

export function orderInfos(params) {
  return request({
    url: "finance/api/v1/orderInfos",
    method: "GET",
    params: params
  });
} // 已完结

export function orderInfoOver(data) {
  return request({
    url: "finance/api/v1/orderInfos",
    method: "PUT",
    data: data
  });
} // 查询全部进行中

export function finish(params) {
  return request({
    url: "/finance/api/v1/orderInfos/finish",
    method: "GET",
    params: params
  });
} // 进入机构

export function orderInfosSvae(orderInfoId, params) {
  return request({
    url: "/finance/api/v1/orderInfos/save/".concat(orderInfoId),
    method: "POST",
    params: params
  });
}