import "core-js/modules/es.array.filter";
import "core-js/modules/es.array.map";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
// ----------------------------------------------------- 依赖导入，包括 api、Form.json、Mixin
import { approve as retrieveReq, customerInfos, allGroupAndFlowByCompanyUser, allCanUseByFlow, getFlowProcessByApproInfo, userAppros, orderInfos, userApprosList } from "../apiApproval";
import RecordForm from "../FormAppory.json";
import { CurdMixin } from "xqjr-plugin-common";
import { formatDate } from "@/utils/date"; // ----------------------------------------------------- 构建表格配置，包括基本项、操作

var columns = RecordForm.list.filter(function (item) {
  return item.customize.table;
}).map(function (item) {
  return {
    dataIndex: item.model,
    customTitle: item.label,
    fixed: item.model === "name" ? "left" : null,
    slots: {
      title: "custom_".concat(item.model)
    },
    help: item.customize.help && item.customize.help.length > 0 ? item.customize.help : null,
    scopedSlots: {
      customRender: item.model
    },
    type: item.type,
    options: item.options,
    sorter: item.customize.sort ? function () {} : null,
    sortDirections: item.customize.sort ? ["descend", "ascend"] : null
  };
}); // ----------------------------------------------------- 生成 mixin

var mixin = new CurdMixin({
  retrieveReq: retrieveReq,
  RecordForm: RecordForm,
  columns: columns
}); // ----------------------------------------------------- 主要内容，一般情况下基本都是空的，所有内容都在 mixin 中

export default {
  components: {},
  filters: {
    filterType: function filterType(val) {
      if (val === 1) {
        return "分销";
      } else if (val === 2) {
        return "分销";
      } else {
        return "";
      }
    },
    status: function status(val) {
      if (val === 2) {
        return "已同意";
      } else if (val === 4) {
        return "已拒绝";
      } else {
        return "待审批";
      }
    }
  },
  mixins: [mixin],
  props: {},
  data: function data() {
    return {
      pageSize: 20
    };
  },
  computed: {
    retrieveParams: function retrieveParams() {
      return {
        request: {
          channelId: localStorage.getItem("lesseeId")
        }
      };
    }
  },
  created: function created() {},
  mounted: function mounted() {},
  methods: {
    // 筛选
    serchEnter: function serchEnter() {}
  }
};